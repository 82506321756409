<template>
    <v-card
        class="mx-auto"
        elevation="0"
        v-if="selectedHospital !== null">
      <v-card-title class="card-header fixed-card-title">
        <v-row class="justify-end d-flex" dense>
          {{$t('view')}} {{$t('hospital')}}
          <v-spacer></v-spacer>
          <v-icon @click="syncronizeDrawers" class="white-icon mr-2">mdi-pencil</v-icon>
          <v-icon @click="closeDrawer" class="white-icon mr-2">mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-container class="mt-5">
        <v-card-text class="mt-10">
        <v-row dense class="pt-0 ">
          <v-col class="text-left pl-0" cols="12">
            <v-chip pill label  color="primary2" class="font-weight-bold white--text  font-size16">{{ selectedHospital.hospitalName }}</v-chip>
          </v-col>
          <v-col class="text-left pb-0 mb-0 pl-0" cols="12">
            <span class="">{{ selectedHospital.medicalInstitutionData.institutionName }}</span>
          </v-col>
          <v-col class="text-left pt-0 mt-0 pl-0" cols="12">
            <span class="">{{ selectedHospital?.address }}</span>
          </v-col>
        </v-row>
        </v-card-text>
        <v-card elevation="3" class="pa-1 mx-3 mt-2 text-left" height="200">
          <span class="table-font-size ml-1">{{ selectedHospital.description }}</span>
        </v-card>
        <v-row v-if="getEcpMappingLocation" dense class="data-table-outline d-flex my-4 pa-2 mx-3 justify-space-between">
          <div class="d-flex align-center">
            <span class="mx-2 font-bold font-size14">{{ $t('migrate-users') }}</span>
            <v-chip color="primary2" dark small v-if="getEcpMappingLocation.userCreationIsAutomatic">
              {{ $t('automatically') }}
            </v-chip>
            <v-chip color="primary" dark small v-if="!getEcpMappingLocation.userCreationIsAutomatic">
              {{ $t('manually') }}
            </v-chip>
          </div>

          <div class="d-flex align-center">
            <span class="mx-2 font-bold font-size14">{{$t('migrate-residents')}}</span>
            <v-chip color="primary2" dark small v-if="getEcpMappingLocation.residentCreationIsAutomatic">
              {{ $t('automatically') }}
            </v-chip>
            <v-chip color="primary" dark small class="mr-2" v-if="!getEcpMappingLocation.residentCreationIsAutomatic">
              {{ $t('manually') }}
            </v-chip>
          </div>
        </v-row>

        <!--        <div class="mt-2 font-weight-bold">Send measurements to eMAR</div>-->
<!--        <v-row dense class="data-table-outline d-flex my-1 pa-2 mx-3 justify-space-between">-->
<!--          <div class="d-flex align-center">-->
<!--            <span class="mx-2 font-size14">{{$t('manual')}}</span>-->
<!--            <v-icon color="green" v-if="manual">-->
<!--              mdi-checkbox-marked-circle-->
<!--            </v-icon>-->
<!--            <v-icon color="primary" class="mr-2" v-if="!manual">-->
<!--              mdi-close-circle-->
<!--            </v-icon>-->
<!--          </div>-->

<!--          <div class="d-flex align-center">-->
<!--            <span class="mx-2 font-size14">{{$t('automatic')}}</span>-->
<!--            <v-icon color="green" v-if="automatic">-->
<!--              mdi-checkbox-marked-circle-->
<!--            </v-icon>-->
<!--            <v-icon color="primary" v-if="!automatic">-->
<!--              mdi-close-circle-->
<!--            </v-icon>-->
<!--          </div>-->
<!--        </v-row>-->
      </v-container>

    </v-card>
</template>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex';
    import { mailPurple, phonePurple, hospitalBlack } from '@/assets';

    export default {
        name: 'HospitalViewDrawer',
        components: {
        },
        data: () => ({
            // valid: true,
          manual: true,
          automatic: false,
            mailPurple: mailPurple,
            phonePurple: phonePurple,
            hospitalBlack: hospitalBlack,
            relatedHospitals: [],
            showHospitals: false,
            // hospitalSpecializationTitle: null,
        }),
        async mounted () {
        },
          beforeDestroy () {
            this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
          },
      computed: {
            ...mapGetters({
              selectedHospital: 'hospitals/getSelectedHospital',
            }),
            ...mapState({
              getEcpMappingLocation: (state) => state.hospitals.getEcpMappingLocation,
              allVerifiers: (state) => state.verifiers.allverifires,
            }),
            hospitalSpecializationTitle: function () {
              const fromVerifier = this.allVerifiers.filter(v => v.rvDomain === 'HOSPITAL_SPECIALIZATION' && v.lowValue === this.selectedHospital.hospitalSpetializationId);
              if (fromVerifier == null || fromVerifier.length === 0) {
                return '';
              } else {
                return fromVerifier[0].shortDescription;
              }
            },
        },
        methods: {
            ...mapActions({
              getHospitalById: 'hospitals/getHospitalById',
            }),
            closeDrawer () {
                this.$store.commit('hospitals/SELECTED_HOSPITAL'); // set selectedHospital = null
                this.$store.commit('SET_EDIT_DETAILS_DRAWER', false);
            },
            syncronizeDrawers () {
                // open drawer for edit
                this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
                  updateDrawer: true,
                  page2: 'offices',
                });
            },
            // async getOfficesForThisMedicalInstitution () {
            //     this.showHospitals = !this.showHospitals;
            //     if (this.showHospitals) {
            //     await this.getMedicalInstitutionById(this.selectedMedicalInstitution.id);
            //     this.relatedHospitals = this.detailedMedicalInstitution.hospitalsData;
            //     }
            // },
        },
    };
</script>
